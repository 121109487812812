<template>
  <div>
    <div id="overtime1"></div>
  </div>
</template>
  
  
<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent,
]);

export default {
  props: {
    list: {
      type: Array,
    },
  },

  watch: {
    list: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },

  mounted() {
    var chartDom = document.getElementById("overtime1");
    chartDom.style.height = "60vh";
    chartDom.style.width = "100%";
    this.chart = echarts.init(chartDom);
    this.renderChart();
  },

  destroyed() {
    this.chart?.dispose();
  },

  methods: {
    renderChart() {
      if (!Array.isArray(this.list)) return;

      this.chart?.clear();

      var option = {
        tooltip: {
          show: false,
          trigger: "item",
        },

        legend: {
          left: "center",
          top: "bottom",
          textStyle: {
            // color: "#fff",
          },
        },

        series: [
          {
            name: "各部门考勤人数/加班人数比例",
            type: "pie",
            radius: ["40%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              formatter: "{b}: {d}%",
            },
            labelLine: {
              show: true,
            },
            data: this.list.map((item) => {
              return {
                name: item.name,
                value: Math.round(item.overtimeUserCount / item.userSum),
              };
            }),
          },
        ],
      };

      option && this.chart?.setOption(option);
    },
  },
};
</script>