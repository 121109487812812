<template>
  <div>
    <div id="overtime-dept-time"></div>
  </div>
</template>
  
  
<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
]);

export default {
  props: {
    list: {
      type: Array,
    },
  },

  watch: {
    list: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },

  mounted() {
    var chartDom = document.getElementById("overtime-dept-time");
    chartDom.style.height = "70vh";
    chartDom.style.width = "100%";
    this.chart = echarts.init(chartDom);
    this.renderChart();
  },

  destroyed() {
    this.chart?.dispose();
  },

  methods: {
    renderChart() {
      if (!Array.isArray(this.list)) return;
      this.chart?.clear();

      const option = {
        tooltip: {
          show: false,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}",
          // valueFormatter: (value) => value,
        },

        legend: {
          show: false,
          left: "center",
          top: "bottom",
          textStyle: {
            // color: "#fff",
          },
        },

        grid: {
          show: false,
          left: "15%",
          top: "5%",
          bottom: "5%",
          right: "10%",
        },

        yAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: "#999",
          },
          data: this.list.map((item) => item.name),
        },
        xAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#999",
              type: "dashed",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#999",
            },
          },
          axisLabel: {
            color: "#999",
            formatter: "{value}",
          },
        },

        series: [
          {
            name: "平均下班时间",
            type: "bar",
            // barWidth: "15px",
            label: {
              show: true,
              formatter: "{b}",
              position: "right",
            },
            data: this.list.map((item) => {
              return {
                name: item.timeAVG,
                value: item.diffAVG,
              };
            }),
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>