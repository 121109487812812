<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px">
          <a-month-picker
            :allowClear="false"
            style="width: 120px"
            v-model="currentMonth"
          />
          <a-select style="width: 120px" v-model="time">
            <a-select-option
              v-for="item in timeList"
              :key="item"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-space>
      </template>
    </Pane>

    <div class="container">
      <a-card class="card">
        <div class="header">
          <div class="title">全院数据</div>
        </div>

        <Analysis
          :list="[
            {
              title: '考勤人数',
              value: lateList.length,
            },
            {
              title: '加班人数',
              value: allPerson,
            },
            {
              title: '平均下班时间',
              value: allGoTimeAVG,
            },
          ]"
        />
      </a-card>

      <Padding />

      <a-card class="card">
        <div class="header">
          <div class="title">各部门考勤人数/加班人数比例</div>
          <div class="extra">
            <a href="#" @click.prevent="downloadDeptCountRatio">导出</a>
          </div>
        </div>
        <div class="print" id="dept-count-ratio">
          <OvertimePieChart :list="depts" />
        </div>
      </a-card>

      <Padding />

      <a-card class="card">
        <div class="header">
          <div class="title">各部门考勤人数/加班人数</div>
          <div class="extra">
            <a href="#" @click.prevent="downloadDeptCount">导出</a>
          </div>
        </div>
        <div class="print" id="dept-count">
          <OvertimeBarChart :list="depts" />
        </div>
      </a-card>

      <Padding />

      <a-card class="card">
        <div class="header">
          <div class="title">各部门平均下班时间</div>
          <div class="extra">
            <a href="#" @click.prevent="downloadDeptTime">导出</a>
          </div>
        </div>
        <div class="print" id="dept-time">
          <OvertimeDeptTime :list="depts" />
        </div>
      </a-card>

      <Padding />

      <a-card class="card">
        <div class="header">
          <div class="title">加班排行榜</div>

          <div class="extra">
            <a-space>
              <a-select
                placeholder="部门"
                show-search
                style="width: 180px"
                :allowClear="true"
                :filter-option="$selectFilterOption"
                v-model="deptName"
              >
                <a-select-option
                  v-for="item in deptList"
                  :key="item"
                  :value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>

              <a-button type="primary" @click="downloadTable">导出</a-button>
            </a-space>
          </div>
        </div>

        <OvertimeTable :list="searchedList" />
      </a-card>

      <Padding />
    </div>
  </div>
</template>
  
<script>
import moment from "moment";
import { fetchAnalysis } from "@/api/human-resources/clock";

import Analysis from "./components/analysis.vue";
import OvertimePieChart from "./components/overtime-pie-chart.vue";
import OvertimeBarChart from "./components/overtime-bar-chart.vue";
import OvertimeDeptTime from "./components/overtime-dept-time.vue";
import OvertimeTable from "./components/overtime-table.vue";

import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import { downloadByHtml } from "@/utils/xlsx";

import { fetchLeave } from "@/api/human-resources/clock";

import { mapActions, mapState } from "vuex";

export default {
  components: {
    Analysis,
    OvertimePieChart,
    OvertimeBarChart,
    OvertimeDeptTime,
    OvertimeTable,
  },

  data() {
    return {
      currentMonth: moment(),
      time: "19:00",
      timeList: [
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],

      list: [],
      lateList: [],
      deptName: undefined,

      allPerson: 0,
      allGoTimeAVG: "",

      depts: [], // 每个部门的加班情况
    };
  },
  computed: {
    deptList() {
      return this.lateList
        .map((item) => item.deptName)
        .filter((item, index, self) => self.indexOf(item) === index);
    },

    searchedList() {
      if (this.deptName) {
        return this.lateList.filter(
          (item) => item.deptName && item.deptName.indexOf(this.deptName) > -1
        );
      } else {
        return this.lateList;
      }
    },

    ...mapState("holiday", ["holiday"]),
  },

  watch: {
    currentMonth() {
      this.getData();
    },
    time() {
      this.setList(this.list);
    },
  },
  mounted() {
    this.getHoliday().then(() => {
      this.getData();
    });
  },
  methods: {
    ...mapActions("holiday", ["getHoliday"]),

    isHoliday(date) {
      let status = "";

      loop: for (let i = 0; i < this.holiday.length; i++) {
        const item = this.holiday[i];
        if (Array.isArray(item.data)) {
          for (let j = 0; j < item.data.length; j++) {
            const element = item.data[j];

            if (
              date.isSameOrAfter(element.startHoliday, "day") &&
              date.isSameOrBefore(element.endHoliday, "day")
            ) {
              status = "holiday";
              break loop;
            }

            for (let x = 0; x < element.workday.length; x++) {
              if (date.isSame(element.workday[x], "day")) {
                status = "workday";
                break loop;
              }
            }
          }
        }
      }

      return status;
    },

    async getData() {
      const hide = this.$message.loading("加载中...", 0);

      const startTime =
        this.currentMonth.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
      const endTime =
        this.currentMonth.endOf("month").format("YYYY-MM-DD") + " 23:59:59";

      const res = await fetchLeave({
        startTime,
        endTime,
        statusList: ["reviewing", "approved"],
      });

      if (Array.isArray(res)) {
        this.leaves = Object.freeze(
          res.filter((item) => item.optType !== "cancel")
        );
      }

      const res2 = await fetchAnalysis({
        workDate: this.currentMonth.format("YYYY-MM"),
      });

      if (Array.isArray(res2)) {
        const list = Object.freeze(res2);
        this.list = list;
        this.setList(list);
      }

      hide();
    },

    // 是否是请假出差公出，如果是不统计加班
    checkHoliday(userId, date) {
      const dateObj = moment(date);

      let flag = false;

      //员工请假出差记录
      const userLeaves = this.leaves.filter((leave) => leave.userId === userId);

      for (let i = 0; i < userLeaves.length; i++) {
        const leave = userLeaves[i];

        if (
          dateObj.isSameOrAfter(leave.startTime, "day") &&
          dateObj.isSameOrBefore(leave.endTime, "day")
        ) {
          flag = true;
          break;
        }
      }

      return flag;
    },

    setList(list) {
      this.lateList = [];

      const arr = [];

      let allPerson = 0; // 只要加过班，就加1
      let allDiffAVG = 0; // 所有人跟17:30的差值求平均数，减去 17:30，就是平均下班时间

      const today = moment();

      list.forEach((item) => {
        let days = 0;
        let length = 0;

        let userDiffAVG = 0;
        let userTime = "";

        if (Array.isArray(item.attList) && item.attList.length > 0) {
          let userDiff = 0;
          let clockDays = 0;

          const done = [];

          item.attList.forEach((element) => {
            // 当天的不计入加班统计

            if (element && today.isSameOrAfter(element.workDate, "days")) {
              if (this.checkHoliday(item.userId, element.workDate)) {
                return;
              }

              const week = moment(element.workDate).day();

              let needCalculate = true;
              const status = this.isHoliday(moment(element.workDate));
              if (status === "holiday") {
                needCalculate = false;
              } else if (status === "workday") {
                needCalculate = true;
              } else {
                if (week === 0 || week === 6) {
                  needCalculate = false;
                } else {
                  needCalculate = true;
                }
              }

              if (!needCalculate) {
                return;
              }

              if (element.checkType === "OverTime") {
                const yesterdayStr = moment(element.userCheckTime)
                  .subtract(1, "days")
                  .format("YYYY-MM-DD");

                const index = done.findIndex(
                  (don) => don.workDate === yesterdayStr
                );

                const params = {
                  workDate: yesterdayStr,
                  clockDays: 1,
                  diff: moment(element.userCheckTime).diff(
                    yesterdayStr + " " + this.time + ":00",
                    "minutes"
                  ),

                  goDiff: moment(element.userCheckTime).diff(
                    yesterdayStr + " 17:30:00",
                    "seconds"
                  ),
                };

                if (index > -1) {
                  done.splice(index, 1, params);
                } else {
                  done.push(params);
                }
              } else if (
                element.checkType === "OffDuty" &&
                this.currentMonth.format("YYYY-MM") ===
                  element.workDate.substring(0, 7) &&
                today.isAfter(element.workDate, "days")
              ) {
                const index = done.findIndex(
                  (don) => don.workDate === element.workDate
                );
                if (index === -1) {
                  const params = {
                    workDate: element.workDate,
                    clockDays: 1,

                    diff: moment(element.userCheckTime).diff(
                      element.workDate + " " + this.time + ":00",
                      "minutes"
                    ),

                    goDiff: moment(element.userCheckTime).diff(
                      element.workDate + " 17:30:00",
                      "seconds"
                    ),
                  };

                  done.push(params);
                }
              }
            }
          });

          done.forEach((item) => {
            clockDays++;

            if (item.diff > 0) {
              days++; // 加班天数
              length += item.diff; // 加班累计时长
            }
            userDiff += item.goDiff;
          });

          if (clockDays > 0) {
            userDiffAVG = Math.round(userDiff / clockDays);
          }

          if (userDiffAVG >= 0) {
            userTime = moment("2022-12-12 " + "17:30:00")
              .add(userDiffAVG, "seconds")
              .format("HH:mm:ss");
          } else {
            userTime = moment("2022-12-12 " + "17:30:00")
              .subtract(Math.abs(userDiffAVG), "seconds")
              .format("HH:mm:ss");
          }
        }

        arr.push({
          userId: item.userId,
          name: item.name,
          deptName: item.deptName,
          days,
          length,
          userDiffAVG,
          time: userTime,
        });

        if (days > 0) {
          allPerson++;
        }
        allDiffAVG += userDiffAVG;
      });

      arr.sort((x, y) => y.userDiffAVG - x.userDiffAVG);

      this.lateList = arr.map((item, index) => {
        return {
          ...item,
          index,
        };
      });

      this.allPerson = allPerson;

      allDiffAVG = Math.round(allDiffAVG / arr.length);
      console.log("allDiffAVG", allDiffAVG);

      let allGoTimeAVG = "";

      if (allDiffAVG >= 0) {
        allGoTimeAVG = moment("2022-12-12 " + "17:30:00")
          .add(allDiffAVG, "seconds")
          .format("HH:mm");
      } else {
        allGoTimeAVG = moment("2022-12-12 " + "17:30:00")
          .subtract(Math.abs(allDiffAVG), "seconds")
          .format("HH:mm");
      }

      this.allGoTimeAVG = allGoTimeAVG;

      const dept = {};
      arr.forEach((item) => {
        if (dept[item.deptName]) {
          dept[item.deptName].diffAVGSum += item.userDiffAVG;
          dept[item.deptName].userSum++;

          if (item.days > 0) {
            dept[item.deptName].overtimeUserCount++;
          }
        } else {
          dept[item.deptName] = {
            diffAVGSum: item.userDiffAVG,
            userSum: 1,
            overtimeUserCount: item.days > 0 ? 1 : 0,
          };
        }
      });

      const depts = [];

      Object.keys(dept).forEach((key) => {
        const diffAVG = dept[key].diffAVGSum / dept[key].userSum;

        let timeAVG = "";

        if (diffAVG >= 0) {
          timeAVG = moment("2022-12-12 " + "17:30:00")
            .add(diffAVG, "seconds")
            .format("HH:mm");
        } else {
          timeAVG = moment("2022-12-12 " + "17:30:00")
            .subtract(Math.abs(diffAVG), "seconds")
            .format("HH:mm");
        }

        depts.push({
          name: key,
          diffAVG: diffAVG,
          timeAVG: timeAVG,
          overtimeUserCount: dept[key].overtimeUserCount,
          userSum: dept[key].userSum,
        });
      });

      this.depts = depts.sort((x, y) => x.diffAVG - y.diffAVG);
    },

    downloadDeptCountRatio() {
      domtoimage
        .toBlob(document.getElementById("dept-count-ratio"))
        .then(function (blob) {
          saveAs(blob, "各部门考勤人数/加班人数比例.png");
        });
    },

    downloadDeptCount() {
      domtoimage
        .toBlob(document.getElementById("dept-count"))
        .then(function (blob) {
          saveAs(blob, "各部门考勤人数/加班人数.png");
        });
    },

    downloadDeptTime() {
      domtoimage
        .toBlob(document.getElementById("dept-time"))
        .then(function (blob) {
          saveAs(blob, "各部门平均下班时间.png");
        });
    },

    downloadTable() {
      downloadByHtml(document.getElementsByTagName("table")[0], "加班排行榜");
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  border-radius: 4px;
  padding: 8px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .title {
      font-weight: bold;
      font-size: 14px;
    }
  }

  .print {
    background-color: #fff;
  }
}
</style>