<template>
    <div class="list">

        <div class="item" v-for='item in list' :key='item.title'>
            <div class="title">
                {{item.title}}
            </div>
            <div>
                     <span class="value">{{item.value}}</span>
                     <span class="unit">{{item.unit}}</span>
                </div>
          
        </div>
 

    </div>
</template>

<script>
export default {
    props:{
        list: {
            type: Array,
            default:()=>[]
        }
    }
}
</script>

<style lang="less" scoped>
.list {
    display: flex;

    .item {
        flex: 1;
    }

    .title {
        color: #999;
        margin-bottom: 8px;
    }

    .value {
        font-size: 24px;
        font-weight: bold;
        color: #1890ff;


        .unit {
            font-size: 16px;
        }
    }

}
</style>