import request from '../request'

// 管理员获取所有考勤记录
export function fetchAnalysis(data) {
    return request({
        // url: '/office-service/ding/attendance/list',
        url: '/office-service/att/access/attendance/list',
        method: 'post',
        data,
    })
}
// 管理员查询出入记录
export function fetchAccessList(data) {
    return request({
        url: '/office-service/att/access/attendance/list/accessDetail',
        method: 'post',
        data,
    })
}

// 用户获取个人考勤记录
export function fetchUserClock(data) {
    return request({
        // url: '/office-service/ding/attendance/user_list',
        url: '/office-service/att/access/attendance/user_list',
        method: 'post',
        data,
    })
}

// 获取用户的剩余假期：年假
export function fetchUserHoliday(data) {
    return request({
        url: '/office-service/att/leave/balance/query',
        method: 'post',
        data,
    })
}

// 管理员获取请假出差列表
export function fetchLeave(data) {
    return request({
        url: '/office-service/att/leave/list',
        method: 'post',
        data,
    })
}
// 用户获取自己的请假出差列表
export function fetchUserLeave(data) {
    return request({
        url: '/office-service/att/leave/user_list',
        method: 'post',
        data,
    })
}
// 请假出差
export function addLeave(data) {
    return request({
        url: '/office-service/att/leave/user_add',
        method: 'post',
        data,
    })
}
// 加班调休
export function reviewMore(params) {
    return request({
        url: '/office-service/att/leave/review/more',
        params,
    })
}
// 加班调休
export function addAdjust(data) {
    return request({
        url: '/office-service/att/adjust/add',
        method: 'post',
        data,
    })
}
// 查询加班调休当月统计
export function fetchAdjust(data) {
    return request({
        url: '/office-service/att/adjust/balance/query',
        method: 'post',
        data,
    })
}
// 查询加班调休当月统计
export function fetchAllAdjust(data) {
    return request({
        url: '/office-service/att/adjust/list',
        method: 'post',
        data,
    })
}
// 查询加班调休记录列表
export function fetchAdjustList(data) {
    return request({
        url: '/office-service/att/adjust/user_list',
        method: 'post',
        data,
    })
}
// 获取请假出差详情
export function fetchLeaveDetail(params) {
    return request({
        url: '/office-service/att/leave/query/' + params.id,
    })
}
// 重新提交请假出差
export function resubmitLeave(data) {
    return request({
        url: '/office-service/att/leave/reAdd',
        method: 'post',
        data,
    })
}

// 管理员查询补卡记录
export function fetchCheckApply(data) {
    return request({
        url: '/office-service/att/checkApply/list',
        method: 'post',
        data,
    })
}
// 用户查询补卡记录
export function fetchUserCheckApply(data) {
    return request({
        url: '/office-service/att/checkApply/user_list',
        method: 'post',
        data,
    })
}
// 补卡
export function addCheckApply(data) {
    return request({
        url: '/office-service/att/checkApply/add',
        method: 'post',
        data,
    })
}
//查询补卡详情
export function fetchCheckDetail(params) {
    return request({
        url: '/office-service/att/checkApply/query/' + params.id,

    })
}
// 重新提交补卡审批
export function resubmitCheck(data) {
    return request({
        url: '/office-service/att/checkApply/reAdd',
        method: 'post',
        data,
    })
}

export function fetchYearDetail() {
    return request({
        url: '/office-service/att/leave/queryAnnualBalance',
    })
}

export function addClock(data) {
    return request({
        url: '/office-service/att/access/attendance/admin/add',
        method: 'post',
        data,
    })
}

export function fetchLeaveReview(params) {
    return request({
          url: '/office-service/att/leave/review',
        params
    })
}
