<template>
  <a-table
    bordered
    :data-source="list"
    :pagination="{
        showSizeChanger: true,
         pageSizeOptions: [ '10',  '50', '100', '200'],
    }"
    rowKey="userId"
  >
    <a-table-column key="index" title="全院排名" align="center">
      <template slot-scope="text">
        <span style="color: #1890ff">{{ text.index + 1}}</span>
      </template>
    </a-table-column>

    <a-table-column key="name" title="姓名" data-index="name"></a-table-column>
    <a-table-column key="deptName" title="部门" data-index="deptName" align="center"></a-table-column>

    <a-table-column
      key="userDiffAVG"
      title="本月平均下班时间"
      align="center"
      :sorter="(x, y) => x.userDiffAVG - y.userDiffAVG"
    >
      <template slot-scope="text">
        <span style="color: #1890ff">{{ text.time }}</span>
      </template>
    </a-table-column>

    <a-table-column key="days" title="本月加班天数" align="center" :sorter="(x, y) => x.days - y.days">
      <template slot-scope="text">
        <span style="color: #1890ff">{{ text.days }}</span>
      </template>
    </a-table-column>
    <a-table-column
      key="length"
      title="本月加班累计时长(分钟)"
      align="center"
      :sorter="(x, y) => x.length - y.length"
    >
      <template slot-scope="text">
        <span style="color: #1890ff">{{ text.length }}</span>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>